<script setup>
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonToolbar,
} from "@ionic/vue";
import {computed} from "vue";
import {QSpace, QTooltip, QImg} from "quasar";

import {useGlobalStore} from "@/store/global";
import {useRoute, useRouter} from "vue-router";
import {DataStore} from "aws-amplify/datastore";
import {DevFittrLast} from "../models";

const store = useGlobalStore();
const route = useRoute();
const router = useRouter();

const {item, dbModel, setFavorite} = defineProps({
  item: Object,
  dbModel: Function,
  setFavorite: Function,
  isFavorite: Function,
});

// Computed properties for cleaner template
const showVelcroButton = computed(() => {
  return (
      item?.attributes?.specs?.includes("6849475f-b7f0-46b5-a9ec-6e5b8f790fe6") &&
      route.path.includes("osb")
  );
});

const showStretchButton = computed(() => {
  return (
      item?.attributes?.specs?.includes("3f122f3e-1936-4364-ba21-14a8c095259c") &&
      route.path.includes("osb")
  );
});

const showBoaButton = computed(() => {
  return (
      item?.attributes?.specs?.includes("29929661-cbee-48a6-83ac-793817614798") &&
      route.path.includes("osb")
  );
});

const showZipperButton = computed(() => {
  return (
      item?.attributes?.closings?.includes(
          "0b535454-074a-45f0-be42-5af1abff277d"
      ) && route.path.includes("osb")
  );
});

const imageSrc = computed(() => store.imagePath + item?.filename);
const imageAlt = computed(() =>
    [item?.brand, item?.modelnumber, item?.colornumber || ""].join(" ")
);

const initModalModels = async (id) => {
  store.isOpen = true;
  store.activeModels = [];

  // Use structuredClone for deep cloning
  const originalModel = store.initialisedModels.find(
      (model) => model.modelID === id
  );
  const model = {...originalModel};

  if (!model) {
    console.error(`Model with ID ${id} not found`);
    return;
  }

  if (route.path.includes("osb")) {
    const lasttypes = model.lasttypes || [];

    // Assuming lasttypes is an array
    const temp = await DataStore.query(store.Lasts, (c) =>
        c.lasttype.eq(lasttypes[0])
    );

    const sizes = temp
        .filter(
            (item) =>
                item.shoesize >= model.sizerangeMin &&
                item.shoesize <= model.sizerangeMax
        )
        .map((item) => item.shoesize.toString())
        .sort((a, b) => a - b);

    model.sizes = sizes;
  }

  store.activeModel = model;
  store.model = model;

  // Fetch last_left and last_right in parallel
  const [last_left, last_right] = await Promise.all([
    DataStore.query(DevFittrLast, (c) =>
        c.and((c) => [
          c.lasttype.eq(model.lasttype_left),
          c.shoesize.eq(model.size_left),
          c.category.contains(store.category),
          c.brand.eq(model.brand),
        ])
    ),
    DataStore.query(DevFittrLast, (c) =>
        c.and((c) => [
          c.lasttype.eq(model.lasttype_right),
          c.shoesize.eq(model.size_right),
          c.category.contains(store.category),
          c.brand.eq(model.brand),
        ])
    ),
  ]);

  store.lastLeft = last_left[0];
  store.lastRight = last_right[0];

  store.activeModels.push(model);

  let modelFamily = [];
  if (dbModel.name === "DevFootcareModel") {
    modelFamily = await DataStore.query(dbModel, (c) =>
        c.and((c) => [
          c.modelnumber.eq(model.modelnumber),
          c.colornumber.ne(model.colornumber),
        ])
    );
  } else {
    modelFamily = await DataStore.query(dbModel, (c) =>
        c.and((c) => [
          c.modelnumber.eq(model.modelnumber),
          c.colornumber.ne(model.colornumber),
          c.brand.eq(model.brand),
        ])
    );

    let lasttypes = model.lasttypes;

    if (typeof lasttypes === "string") {
      try {
        lasttypes = JSON.parse(lasttypes);
      } catch (error) {
        console.error("Error parsing lasttypes:", error);
        lasttypes = [];
      }
    }

    if (!Array.isArray(lasttypes)) {
      lasttypes = [lasttypes];
    }

    const lastSizesPromises = lasttypes.map(async (lasttype) => {
      const temp = await DataStore.query(store.Lasts, (c) =>
          c.and((c) => [
            c.lasttype.eq(lasttype.toString()),
            c.category.contains(store.category),
          ])
      );

      const sizes = temp
          .map((item) => parseFloat(item.shoesize))
          .sort((a, b) => a - b);

      return {
        lasttype,
        sizes,
      };
    });

    store.lastSizes = await Promise.all(lastSizesPromises);
  }

  // Add model family items to activeModels
  modelFamily.forEach((modelFamilyItem) => {
    const tempModel = store.initialisedModels.find(
        (model) => model.modelID === modelFamilyItem.id
    );
    if (tempModel) store.activeModels.push(tempModel);
  });

  store.isOpenRef = true;
};

</script>

<template>
  <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 model-card-container ion-no-margin ion-no-border">
    <ion-card button class="model-card">
      <ion-toolbar style="--background: transparent">
        <ion-buttons slot="start" style="padding-left: 8px; padding-right: 8px">
          <ion-button slot="start" shape="round" @click.stop="setFavorite(item.modelID)">
            <q-tooltip :delay="500" max-width="50%" style="font-size: 16px">
              Selecteer als favoriet
            </q-tooltip>
            <i slot="icon-only" style="
                font-size: 26px;
                opacity: 0.75;
                color: var(--ion-color-secondary);
              " :class="
                isFavorite(item.modelID) ? 'fas fa-heart' : 'fal fa-heart'
              " :id="'favo_' + item.modelID"></i>
          </ion-button>
          <q-space></q-space>
          <ion-button v-if="showStretchButton" slot="end" shape="round">
            <img slot="icon-only" alt="Stretch" class="icon-image" style="border-radius: 0" src="../assets/stretch2.png"/>
            <q-tooltip :delay="500" max-width="40%" style="font-size: 16px">
              Dit model is standaard uitgevoerd met stretch materiaal.
            </q-tooltip>
          </ion-button>
          <ion-button v-if="showZipperButton" slot="end" shape="round">
            <img style="max-width:unset" slot="icon-only" alt="Zipper" class="icon-image" src="../assets/zipper.png"/>
            <q-tooltip :delay="500" max-width="50%" style="font-size: 16px">
              Dit model heeft een rits die mogelijk niet goed zichtbaar is op de
              foto.
            </q-tooltip>
          </ion-button>

        </ion-buttons>
        <ion-buttons slot="end" style="padding-left: 8px; padding-right: 8px">
          <ion-button v-if="showVelcroButton" slot="end" shape="round">
            <img slot="icon-only" alt="Velcro" class="icon-image" src="../assets/velcro.png"/>
            <q-tooltip :delay="500" max-width="50%" style="font-size: 16px">
              Dit model is ook beschikbaar met klittenbandsluiting. U kunt dit
              aangeven in het bestelformulier indien gewenst.
            </q-tooltip>
          </ion-button>
          <ion-button v-if="showBoaButton" slot="end" shape="round">
            <img style="max-width:unset" slot="icon-only" alt="Boa" class="boa-icon-image" src="../assets/boa.png"/>
            <q-tooltip :delay="500" max-width="50%" style="font-size: 16px">
              Dit model is ook beschikbaar met een BOA draaisluiting. U kunt dit
              aangeven in het bestelformulier indien gewenst.
            </q-tooltip>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-card-content @click="initModalModels(item?.modelID)" tappable class="card-content">
        <div v-if="item.brand === 'Hartjes' && router.options.history.state.back !== '/home'">
          Stock: {{item.attributes.stock_volumes}}
        </div>
        <ion-item class="card-item">
          <q-img placeholder-src="../assets/img/blank.png" class="model-image" :ratio="1" no-spinner loading="eager" fit="contain" :draggable="false" :src="imageSrc" :alt="imageAlt"/>
        </ion-item>

        <ion-card-title class="card-title">
          <span>Model: {{ item?.modelnumber }}</span>
        </ion-card-title>

        <ion-card-subtitle class="card-subtitle">
          <span :style="[item?.colornumber ? 'opacity:1' : 'opacity:0' ]">
            {{ "Kleur: " + item?.colornumber }}
          </span>
        </ion-card-subtitle>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<style scoped>
.model-card-container {
  margin: 0;
  padding: 0;
  width: 100%;
}

.model-card {
  margin: 12px;
}

.favorite-icon {
  opacity: 0.75;
  color: var(--ion-color-secondary);
  font-size: 26px;
}

.icon-image {
  border-radius: 75px;
  opacity: 0.75;
  height: 28px;
  width: 28px;
}

.boa-icon-image {
  border-radius: 75px;
  opacity: 0.75;
  height: 33px;
  width: 33px;
}

.card-content {
  padding-top: 0;
  padding-bottom: 4px;
}

.card-item {
  --inner-padding-end: 0;
  --padding-start: 0;
  z-index: 0;
}

.model-image {
  margin-bottom: 10px;
  border-radius: 8px;
}

.card-title {
  font-size: 1em;
  //color: var(--ion-color-primary);
  padding-left: 10px;
  margin-top: 5px;
}

.card-subtitle {
  padding-left: 12px;
}
</style>
